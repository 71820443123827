<template>
  <div>
    <Header></Header>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "./views/components/header.vue";
export default {
  components: {
    Header
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
  font-family: Monospace;
}

a,
button,
input,
select {
  pointer-events: auto;
}
</style>
