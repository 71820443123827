<template>
  <div id="category" v-show="showCate">
    <div class="router_link_h">
      <ul>
        <li>
          <router-link to="/intro"
            ><span @click="closeCateMan()">intro</span></router-link
          >
        </li>
        <li>
          <router-link to="/about"
            ><span @click="closeCateMan()">about</span></router-link
          >
        </li>
        <li>
          <router-link to="/port"
            ><span @click="closeCateMan()">port polio</span></router-link
          >
        </li>
        <li>
          <router-link to="/contact"
            ><span @click="closeCateMan()">contact</span></router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showCate"],
  methods: {
    closeCateMan() {
      console.log("닫아라");
      this.$emit("closeCateMan");
    }
  }
};
</script>

<style>
ul,
li {
  list-style-type: none;
}
@keyframes slidein {
  0% {
    margin-left: 50%;
    opacity: 0;
  }
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}
#category {
  animation: slidein 2s;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 90vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
}
.router_link_h {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 1;
  text-transform: uppercase;
  font-family: sans-serif;
  font-style: italic;
  background: linear-gradient(#262626, #eaeaea, #262626);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px #262626;
}

.router_link_h a {
  display: block;
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0.5;
}

.router_link_h a:hover {
  color: white;
  opacity: 1;
  background: linear-gradient(#eaeaea, #262626, #eaeaea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px #262626;
}

.router_link_h .router-link-exact-active {
  color: white;
  opacity: 1;
}

.router_link_h .router-link-exact-active:hover {
  cursor: default;
  pointer-events: none;
  color: white;
  opacity: 1;
}
</style>
