<template>
  <div>
    <div class="header">
      <router-link to="/" class="logo">Hak's story</router-link>
      <div class="toggle" @click="showCate1 = !showCate1"></div>
    </div>
    <transition name="fade">
      <category
        v-bind:showCate="showCate1"
        v-on:closeCateMan="closeCateMan"
      ></category>
    </transition>
  </div>
</template>

<script>
import category from "./category1.vue";
export default {
  components: {
    category
  },
  data() {
    return {
      showCate1: false
    };
  },
  methods: {
    closeCateMan() {
      this.showCate1 = !this.showCate1;
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.header {
  z-index: 5;
  padding: 40px 100px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .logo {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.header .toggle {
  color: white;
  position: relative;
  height: 28px;
  width: 36px;
  background: url(../../assets/images/toggle.png);
  background-size: cover;
  cursor: pointer;
  filter: brightness(1.75);
}
.header .toggle:hover {
  opacity: 0.7;
}

@media (max-width: 613px) {
  .header {
    padding: 40px 60px;
  }

  .header .logo {
    font-size: 22px;
  }

  .header .toggle {
    width: 30px;
    height: 22px;
  }
}

@media (max-width: 400px) {
  .header {
    padding: 40px 45px;
  }

  .header .logo {
    font-size: 18px;
  }

  .header .toggle {
    width: 25px;
    height: 19px;
  }
}
</style>
