import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "intro",
    component: () => import("../views/pages/intro.vue"),
  },
  {
    path: "/intro",
    name: "intro",
    component: () => import("../views/pages/intro.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/pages/contact.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/pages/aboutme.vue"),
  },
  {
    path: "/port",
    name: "port",
    component: () => import("../views/pages/portpolio.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//전연 라우팅 -> 모든 라우팅에 대해서

export default router;
