import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import firebase from "./plugins/firebase";

new Vue({
  axios,
  router,
  render: (h) => h(App),
}).$mount("#app");
